import React from "react";
import getInternalLink from "./InternalLink";

const serializers = {
  types: {
    block: props => {
      return <h1 className="h0">{props.children}</h1>;
    },
  },
  marks: {
    internalLink: getInternalLink,
  },
};

export default serializers;
