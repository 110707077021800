import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "../helper/HomeSerializer";
import SEO from "../components/seo";
import Fader from "../helper/Fader";

export const query = graphql`
  {
    allSanityHome {
      edges {
        node {
          id
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          backgroundImage {
            asset {
              fixed(width: 1600, height: 900) {
                ...GatsbySanityImageFixed_withWebp_noBase64
              }
            }
          }
          title
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  }
`;

class HomePage extends React.Component {
  render() {
    const home = this.props.data.allSanityHome.edges[0].node;
    return (
      <Layout
        className="page-home-container"
        backgroundImage={home.backgroundImage?.asset?.fixed}
      >
        <SEO
          title={(home.pageSEO && home.pageSEO.metaTitle) || home.title}
          description={home.pageSEO && home.pageSEO.metaDescription}
          image={home.pageSEO && home.pageSEO.metaImage}
        />
        <Fader>
          <section className="home-body">
            {home._rawIntro && (
              <BlockContent
                blocks={home._rawIntro.textBlock}
                serializers={serializers}
              />
            )}
            {!home._rawIntro && <h1 className="h0">Simpsons</h1>}
          </section>
        </Fader>
      </Layout>
    );
  }
}

export default HomePage;
